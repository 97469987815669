import { notification } from 'antd';
import cn from 'classnames';
import { paymentInvoicesTableFiltersSelectors } from 'store/invoices/payment/paymentInvoicesTableFiltersSelectors';
import { paymentInvoicesTableSelectors } from 'store/invoices/payment/paymentInvoicesTableSelectors';
import { usePaymentInvoiceTable } from 'store/invoices/payment/usePaymentInvoiceTable';
import { usePaymentInvoiceTableFilters } from 'store/invoices/payment/usePaymentInvoiceTableFilters';
import Loader from 'ui/Loader';
import Table from 'ui/Table';
import { dateHelpers } from 'utils/helpers/dateHelpers';
import { getSortParams } from 'utils/helpers/tableHelpers';
import type { TComponent } from 'utils/types/component';

import { useOnce } from 'components/utils/hooks/useOnce';

import type { IPaymentInvoice } from 'models/Invoice/Payment';
import type { TTableSort } from 'models/Table';

import { Filters } from './components/Filters';
import { CustomPagination } from './components/Paginations';
import { COLUMNS } from './columns';
import { paymentInvoicesHelpers } from './helpers';
import styles from './PaymentInvoicesTable.module.scss';

const TABLE_VERTICAL_SCROLL_WIDTH = 1600;

export const PaymentInvoicesTable: TComponent = () => {
    const data = usePaymentInvoiceTable(paymentInvoicesTableSelectors.rows);
    const loading = usePaymentInvoiceTable(
        paymentInvoicesTableSelectors.isLoading,
    );
    const getTable = usePaymentInvoiceTable(
        paymentInvoicesTableSelectors.getTable,
    );
    const exportPaymentInvoices = usePaymentInvoiceTable(
        paymentInvoicesTableSelectors.exportPaymentInvoices,
    );

    const body = usePaymentInvoiceTableFilters(
        paymentInvoicesTableFiltersSelectors.getBody,
    );
    const params = usePaymentInvoiceTableFilters(
        paymentInvoicesTableFiltersSelectors.getParams,
    );
    const getCurrenciesOptions = usePaymentInvoiceTableFilters(
        paymentInvoicesTableFiltersSelectors.getCurrenciesOptions,
    );

    const handleExport = (email: string) => {
        const startDate = body.dateFilter?.createdAtFrom;
        const endDate = body.dateFilter?.createdAtTo;

        if (startDate && endDate) {
            const isWithin14Days = dateHelpers.isWithin14Days(
                startDate,
                endDate,
            );

            if (!isWithin14Days) {
                return notification.error({
                    message:
                        'Максимально возможный период для выгрузки списка транзакций - 14 дней.',
                });
            }

            if (!paymentInvoicesHelpers.isDateRangeValid(startDate, endDate)) {
                return notification.error({
                    message:
                        'Выбранный период должен ограничиваться текущей датой.',
                });
            }

            exportPaymentInvoices({
                filter: body,
                email,
            });
        } else {
            notification.error({
                message:
                    'Экспорт таблицы возможен только при установленных фильтрах по дате.',
            });
        }
    };

    const handleSortTable = (
        _: number,
        sortParams: TTableSort<IPaymentInvoice>,
    ) => {
        const { property, order } = getSortParams(sortParams);

        const newParams = { ...params, sort: `${property},${order}` };

        getTable(body, newParams);
    };

    useOnce(
        () => true,
        () => {
            getTable(body, params);
            getCurrenciesOptions();
        },
        [],
    );

    return (
        <Loader active={loading}>
            <div className={styles.container}>
                <div className={cn(styles.actionBar, 'actionBar')}>
                    <Filters onExport={handleExport} />
                </div>
                <Table
                    scroll={{ x: TABLE_VERTICAL_SCROLL_WIDTH }}
                    columns={COLUMNS}
                    tableRows={data}
                    onChange={handleSortTable}
                />
                <div className={styles.tableBottom}>
                    <CustomPagination />
                </div>
            </div>
        </Loader>
    );
};
