import { Pagination } from 'antd';
import { paymentInvoicesTableFiltersSelectors } from 'store/invoices/payment/paymentInvoicesTableFiltersSelectors';
import { paymentInvoicesTableSelectors } from 'store/invoices/payment/paymentInvoicesTableSelectors';
import { usePaymentInvoiceTable } from 'store/invoices/payment/usePaymentInvoiceTable';
import { usePaymentInvoiceTableFilters } from 'store/invoices/payment/usePaymentInvoiceTableFilters';
import type { TComponent } from 'utils/types/component';

export const CustomPagination: TComponent = () => {
    const params = usePaymentInvoiceTableFilters(
        paymentInvoicesTableFiltersSelectors.getParams,
    );
    const body = usePaymentInvoiceTableFilters(
        paymentInvoicesTableFiltersSelectors.getBody,
    );
    const totalElements = usePaymentInvoiceTable(
        paymentInvoicesTableSelectors.totalElements,
    );

    const setParams = usePaymentInvoiceTableFilters(
        paymentInvoicesTableFiltersSelectors.setParams,
    );
    const getTable = usePaymentInvoiceTable(
        paymentInvoicesTableSelectors.getTable,
    );

    const page = params.page + 1;

    const handleChangePage = (currentPage: number, pageSize: number) => {
        const enhancePage = currentPage - 1;

        const newParams = { ...params, page: enhancePage, size: pageSize };

        setParams(newParams);
        getTable(body, newParams);
    };

    return (
        <Pagination
            showSizeChanger
            current={page}
            total={totalElements}
            onChange={handleChangePage}
        />
    );
};
