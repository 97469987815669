import { Typography } from 'antd';
import cn from 'classnames';
import Clipboard from 'ui/Clipboard';
import type { TComponent } from 'utils/types/component';

import type { IPayoutInvoice } from 'models/Invoice/Payout';

import styles from './IdentityCell.module.scss';

interface IIdentityCellProps {
    record: IPayoutInvoice;
}

export const IdentityCell: TComponent<IIdentityCellProps> = (props) => {
    const { record } = props;

    const identity = record.identity;

    return (
        <Clipboard textToCopy={identity}>
            <Typography className={cn(styles.text, 'textEllipsis')}>
                {identity}
            </Typography>
        </Clipboard>
    );
};
