import { dateHelpers } from 'utils/helpers/dateHelpers';

const isDateRangeValid = (dateFrom: string, dateTo: string) => {
    const isFromValid = dateHelpers.isSameOrBefore(dateFrom);
    const isToValid = dateHelpers.isSameOrBefore(dateTo);

    return isFromValid && isToValid;
};

export const payoutInvoicesHelpers = {
    isDateRangeValid,
};
