import { INVOICES_CONSTANTS } from 'utils/constants/invoices';
import { dateHelpers } from 'utils/helpers/dateHelpers';

import { IdentityCell } from 'components/domains/Invoices/Payment/components/IdentityCell';

import type { IPaymentInvoice } from 'models/Invoice/Payment';
import type { TTableColumn } from 'models/Table';

export const COLUMNS: TTableColumn<IPaymentInvoice>[] = [
    {
        dataIndex: 'identity',
        key: 'identity',
        title: 'ID',
        render: (_, record) => <IdentityCell record={record} />,
    },
    {
        dataIndex: 'userId',
        key: 'userId',
        title: 'ID клиента',
    },

    {
        dataIndex: 'externalIdentity',
        key: 'externalIdentity',
        title: 'ID заявки на сайте',
    },

    {
        dataIndex: 'amount',
        key: 'amount',
        title: 'Сумма',
    },
    {
        dataIndex: 'currencyCode',
        key: 'currencyCode',
        title: 'Валюта',
    },
    {
        dataIndex: 'status',
        key: 'status',
        title: 'Статус',
    },
    {
        dataIndex: 'createdAt',
        key: 'createdAt',
        title: 'Дата создания',
        render: (_, record) =>
            record.createdAt
                ? dateHelpers.formatTo(
                      record.createdAt,
                      INVOICES_CONSTANTS.VIEW_DATE_FORMAT,
                  )
                : '',
    },
    {
        dataIndex: 'finishedAt',
        key: 'finishedAt',
        title: 'Дата финализации',
        render: (_, record) =>
            record.finishedAt
                ? dateHelpers.formatTo(
                      record.finishedAt,
                      INVOICES_CONSTANTS.VIEW_DATE_FORMAT,
                  )
                : '',
    },
];
