import { Pagination } from 'antd';
import { historyPaymentsTableFiltersSelectors } from 'store/merchants/historyPaymentsTableFiltersSelectors';
import { historyPaymentsTableSelectors } from 'store/merchants/historyPaymentsTableSelectors';
import { useHistoryPaymentsTable } from 'store/merchants/useHistoryPaymentsTable';
import { useHistoryPaymentsTableFilters } from 'store/merchants/useHistoryPaymentsTableFilters';
import type { TComponent } from 'utils/types/component';

export const CustomPagination: TComponent = () => {
    const params = useHistoryPaymentsTableFilters(
        historyPaymentsTableFiltersSelectors.getParams,
    );
    const body = useHistoryPaymentsTableFilters(
        historyPaymentsTableFiltersSelectors.getBody,
    );
    const totalElements = useHistoryPaymentsTable(
        historyPaymentsTableSelectors.totalElements,
    );

    const setParams = useHistoryPaymentsTableFilters(
        historyPaymentsTableFiltersSelectors.setParams,
    );
    const getTable = useHistoryPaymentsTable(
        historyPaymentsTableSelectors.getTable,
    );

    const page = params.page + 1;

    const handleChangePage = (currentPage: number, pageSize: number) => {
        const enhancePage = currentPage - 1;

        const newParams = { ...params, page: enhancePage, size: pageSize };

        setParams(newParams);
        getTable(body, newParams);
    };

    return (
        <Pagination
            showSizeChanger
            current={page}
            total={totalElements}
            onChange={handleChangePage}
        />
    );
};
