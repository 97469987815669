import { useState } from 'react';
import type { DatePickerProps } from 'antd';
import { Button, DatePicker, Form } from 'antd';
import { historyPaymentsTableFiltersSelectors } from 'store/merchants/historyPaymentsTableFiltersSelectors';
import { historyPaymentsTableSelectors } from 'store/merchants/historyPaymentsTableSelectors';
import { merchantsSelectors } from 'store/merchants/merchantsSelectors';
import { useHistoryPaymentsTable } from 'store/merchants/useHistoryPaymentsTable';
import { useHistoryPaymentsTableFilters } from 'store/merchants/useHistoryPaymentsTableFilters';
import { useMerchants } from 'store/merchants/useMerchants';
import MultiSelect from 'ui/controls/MultiSelect';
import { Search } from 'ui/controls/Search';
import { dateHelpers } from 'utils/helpers/dateHelpers';
import { predicates } from 'utils/helpers/predicates';
import type { TComponent } from 'utils/types/component';

import omitBy from 'lodash/omitBy';

import styles from './Filters.module.scss';
import { filtersHelpers } from './helpers';
import type { IFilterForm } from './types';

const INITIAL_FILTERS: IFilterForm = {
    searchStr: '',
    startDate: '',
    endDate: '',
    currencyCodes: [],
};

export const Filters: TComponent = () => {
    const [form] = Form.useForm<IFilterForm>();
    const [formData, setFormData] = useState<IFilterForm>(INITIAL_FILTERS);

    const currenciesOptions = useMerchants(
        merchantsSelectors.currenciesOptions,
    );
    const params = useHistoryPaymentsTableFilters(
        historyPaymentsTableFiltersSelectors.getParams,
    );
    const clearBody = useHistoryPaymentsTableFilters(
        historyPaymentsTableFiltersSelectors.clearBody,
    );
    const setBody = useHistoryPaymentsTableFilters(
        historyPaymentsTableFiltersSelectors.setBody,
    );
    const setParams = useHistoryPaymentsTableFilters(
        historyPaymentsTableFiltersSelectors.setParams,
    );
    const getMany = useHistoryPaymentsTable(
        historyPaymentsTableSelectors.getTable,
    );

    const initStartDate = formData.startDate
        ? dateHelpers.formatDays(formData.startDate)
        : null;
    const initEndDate = formData.endDate
        ? dateHelpers.formatDays(formData.endDate)
        : null;

    const handleClearFilters = () => {
        form.resetFields();

        setFormData(INITIAL_FILTERS);

        clearBody();
    };

    const onSubmit = () => {
        const enhanceData = omitBy(
            filtersHelpers.getFiltersData(formData),
            (field) =>
                predicates.isUndefined(field) ||
                predicates.isEmptyString(field) ||
                predicates.isEmptyArray(field),
        );

        const newParams = { ...params, page: 0 };

        setBody(enhanceData);
        setParams(newParams);

        getMany(enhanceData, newParams);
    };

    const handleChangeCurrency = (
        currencyCodes: IFilterForm['currencyCodes'],
    ) => {
        setFormData((data) => ({
            ...data,
            currencyCodes,
        }));
    };

    const handleChangeStartDate: DatePickerProps['onChange'] = async (
        _,
        startDate,
    ) => {
        setFormData((data) => ({
            ...data,
            startDate,
        }));
    };

    const handleChangeEndDate: DatePickerProps['onChange'] = async (
        _,
        endDate,
    ) => {
        setFormData((data) => ({
            ...data,
            endDate,
        }));
    };

    const handleChangeSearchStr = (searchStr: IFilterForm['searchStr']) => {
        setFormData((data) => ({
            ...data,
            searchStr,
        }));
    };

    return (
        <Form form={form} onFinish={onSubmit} className={styles.leftControls}>
            <div className={styles.controlsContainer}>
                <Search
                    value={formData.searchStr}
                    onChange={handleChangeSearchStr}
                    className={styles.search}
                    placeholder='Поиск'
                />
                <DatePicker
                    value={initStartDate}
                    onChange={handleChangeStartDate}
                    placeholder='Дата (от)'
                    className={styles.datePicker}
                />
                <DatePicker
                    value={initEndDate}
                    onChange={handleChangeEndDate}
                    placeholder='Дата (до)'
                    className={styles.datePicker}
                />
            </div>
            <div className={styles.controlsContainer}>
                <MultiSelect
                    value={formData.currencyCodes}
                    onChange={handleChangeCurrency}
                    placeholder='Валюта'
                    options={currenciesOptions}
                    maxTagCount='responsive'
                    className={styles.multiSelect}
                />
            </div>
            <div className={styles.controlsContainer}>
                <Button type='primary' htmlType='submit'>
                    Применить
                </Button>
                <Button type='default' onClick={handleClearFilters}>
                    Очистить
                </Button>
            </div>
        </Form>
    );
};
