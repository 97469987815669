import type { TBalanceFindBody } from 'api/merchants/balance/types';
import { BALANCES_CONSTANTS } from 'utils/constants/balances';
import { dateHelpers } from 'utils/helpers/dateHelpers';

import type { IFilterForm } from '../types';

const getFiltersData = (data: IFilterForm) => {
    if (!Object.keys(data).length) {
        return;
    }

    const result: TBalanceFindBody = {};

    if (data.date) {
        result.date = dateHelpers.formatTo(
            data.date,
            BALANCES_CONSTANTS.FILTER_DATE_FORMAT,
        );
    }

    if (data.searchStr) {
        result.searchStr = data.searchStr;
    }

    if (data.currencyCodes) {
        result.currencyCodes = data.currencyCodes;
    }

    if (data.payoutMethodNames) {
        result.payoutMethodNames = data.payoutMethodNames;
    }

    if (data.paymentMethodNames) {
        result.paymentMethodNames = data.paymentMethodNames;
    }

    return result;
};

export const filtersHelpers = { getFiltersData };
