import { Button, Form, Input, Modal } from 'antd';
import type { TComponent } from 'utils/types/component';

import styles from './InvoicesExportModal.module.scss';

interface IInvoicesExportModalProps {
    onSave: (email: string) => void;
    onClose: () => void;
}

export const InvoicesExportModal: TComponent<IInvoicesExportModalProps> = (
    props,
) => {
    const { onClose, onSave } = props;

    const [form] = Form.useForm();

    const handleSend = async () => {
        try {
            await form.validateFields();
            onSave(form.getFieldValue('email'));
            onClose();
        } catch {}
    };

    return (
        <Modal
            open
            onCancel={onClose}
            footer={[
                <Button
                    className={styles.sendBtn}
                    onClick={handleSend}
                    key='send'
                    type='primary'
                >
                    Отправить
                </Button>,
            ]}
            title='Экспортировать инвойсы'
        >
            <Form form={form} layout='vertical'>
                <Form.Item
                    name='email'
                    label='Введите email'
                    rules={[
                        {
                            required: true,
                            message: 'Email обязательное поле',
                        },
                        {
                            type: 'email',
                            message: 'Некорректный email',
                        },
                    ]}
                >
                    <Input placeholder='example@test.com' />
                </Form.Item>
            </Form>
        </Modal>
    );
};
