import { PREPAYMENTS_CONSTANTS } from 'utils/constants/prepayments';
import { dateHelpers } from 'utils/helpers/dateHelpers';
import { prepaymentsHelpers } from 'utils/helpers/prepayments';

import type { THistoryPayment } from 'models/Merchant/HistoryPayment';
import type { TTableColumn } from 'models/Table';

export const COLUMNS: TTableColumn<THistoryPayment>[] = [
    {
        dataIndex: 'identity',
        key: 'identity',
        title: 'Identity',
    },

    {
        dataIndex: 'balanceChangeReason',
        key: 'balanceChangeReason',
        title: 'Причина корректировки',
        render: (_, record) =>
            prepaymentsHelpers.getReason(record.balanceChangeReason),
    },

    {
        dataIndex: 'currencyCode',
        key: 'currencyCode',
        title: 'Валюта',
    },

    {
        dataIndex: 'inputAmount',
        key: 'inputAmount',
        title: 'Введенная сумма',
        sorter: true,
    },

    {
        dataIndex: 'adjustmentFee',
        key: 'adjustmentFee',
        title: 'Комиссия мерчанта',
        sorter: true,
    },

    {
        dataIndex: 'finalAmount',
        key: 'finalAmount',
        title: 'Итоговая сумма',
        sorter: true,
    },

    {
        dataIndex: 'createdBy',
        key: 'createdBy',
        title: 'Email пользователя',
    },
    {
        dataIndex: 'createdAt',
        key: 'createdAt',
        title: 'Дата создания',
        render: (_, record) =>
            dateHelpers.formatTo(
                record.createdAt,
                PREPAYMENTS_CONSTANTS.VIEW_DATE_FORMAT,
            ),
    },
    {
        dataIndex: 'comment',
        key: 'comment',
        title: 'Комментарий',
    },
];
