import { Pagination } from 'antd';
import { balanceTableFiltersSelectors } from 'store/merchants/balanceTableFiltersSelectors';
import { balanceTableSelectors } from 'store/merchants/balanceTableSelectors';
import { useBalanceTable } from 'store/merchants/useBalanceTable';
import { useBalanceTableFilters } from 'store/merchants/useBalanceTableFilters';
import type { TComponent } from 'utils/types/component';

export const CustomPagination: TComponent = () => {
    const params = useBalanceTableFilters(
        balanceTableFiltersSelectors.getParams,
    );
    const body = useBalanceTableFilters(balanceTableFiltersSelectors.getBody);
    const totalElements = useBalanceTable(balanceTableSelectors.totalElements);

    const setParams = useBalanceTableFilters(
        balanceTableFiltersSelectors.setParams,
    );
    const fetchBalances = useBalanceTable(balanceTableSelectors.getTable);

    const page = params.page + 1;

    const handleChangePage = (currentPage: number, pageSize: number) => {
        const enhancePage = currentPage - 1;

        const newParams = { ...params, page: enhancePage, size: pageSize };

        setParams(newParams);
        fetchBalances(body, newParams);
    };

    return (
        <Pagination
            showSizeChanger
            current={page}
            total={totalElements}
            onChange={handleChangePage}
        />
    );
};
