import { Pagination } from 'antd';
import { payoutInvoicesTableFiltersSelectors } from 'store/invoices/payout/payoutInvoicesTableFiltersSelectors';
import { payoutInvoicesTableSelectors } from 'store/invoices/payout/payoutInvoicesTableSelectors';
import { usePayoutInvoiceTable } from 'store/invoices/payout/usePayoutInvoiceTable';
import { usePayoutInvoiceTableFilters } from 'store/invoices/payout/usePayoutInvoiceTableFilters';
import type { TComponent } from 'utils/types/component';

export const CustomPagination: TComponent = () => {
    const params = usePayoutInvoiceTableFilters(
        payoutInvoicesTableFiltersSelectors.getParams,
    );
    const body = usePayoutInvoiceTableFilters(
        payoutInvoicesTableFiltersSelectors.getBody,
    );
    const totalElements = usePayoutInvoiceTable(
        payoutInvoicesTableSelectors.totalElements,
    );

    const setParams = usePayoutInvoiceTableFilters(
        payoutInvoicesTableFiltersSelectors.setParams,
    );
    const getTable = usePayoutInvoiceTable(
        payoutInvoicesTableSelectors.getTable,
    );

    const page = params.page + 1;

    const handleChangePage = (currentPage: number, pageSize: number) => {
        const enhancePage = currentPage - 1;

        const newParams = { ...params, page: enhancePage, size: pageSize };

        setParams(newParams);
        getTable(body, newParams);
    };

    return (
        <Pagination
            showSizeChanger
            current={page}
            total={totalElements}
            onChange={handleChangePage}
        />
    );
};
