import type {
    TPaymentPaymentMethod,
    TPayoutPaymentMethod,
} from 'api/merchants/balance/types';

import type { TTableColumn } from 'models/Table';

const depositColumns: TTableColumn<TPaymentPaymentMethod>[] = [
    {
        title: 'Method name',
        dataIndex: 'methodName',
        key: 'paymentMethodName',
        ellipsis: true,
    },
    {
        title: 'Fee for deposit %',
        dataIndex: 'paymentCommissionPercent',
        key: 'paymentCommissionPercent',
        sorter: true,
    },
    {
        title: 'Fee for deposit fixed',
        dataIndex: 'paymentCommissionFixed',
        key: 'paymentCommissionFixed',
        sorter: true,
    },
    {
        title: 'Amount of deposit for cd',
        dataIndex: 'todayPaymentAmount',
        key: 'todayPaymentAmount',
        sorter: true,
    },
    {
        title: 'Amount of commissions for deposit for the cd',
        dataIndex: 'todayPaymentCommissionAmount',
        key: 'todayPaymentCommissionAmount',
        sorter: true,
    },
];

const payoutsColumns: TTableColumn<TPayoutPaymentMethod>[] = [
    {
        title: 'Method name',
        dataIndex: 'methodName',
        key: 'payoutMethodName',
        ellipsis: true,
    },
    {
        title: 'Fee for payout %',
        dataIndex: 'payoutCommissionPercent',
        key: 'payoutCommissionPercent',
        sorter: true,
    },
    {
        title: 'Fee for payout fixed',
        dataIndex: 'payoutCommissionFixed',
        key: 'payoutCommissionFixed',
        sorter: true,
    },
    {
        title: 'Amount of payout for cd',
        dataIndex: 'todayPayoutAmount',
        key: 'todayPayoutAmount',
        sorter: true,
    },
    {
        title: 'Amount of commissions for payout for the cd',
        dataIndex: 'todayPayoutCommissionAmount',
        key: 'todayPayoutCommissionAmount',
        sorter: true,
    },
];

export const columns = {
    depositColumns,
    payoutsColumns,
};
