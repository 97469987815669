import { notification } from 'antd';
import cn from 'classnames';
import { historySettlementsTableFiltersSelectors } from 'store/merchants/historySettlementsTableFiltersSelectors';
import { historySettlementsTableSelectors } from 'store/merchants/historySettlementsTableSelectors';
import { useHistorySettlementsTable } from 'store/merchants/useHistorySettlementsTable';
import { useHistorySettlementsTableFilters } from 'store/merchants/useHistorySettlementsTableFilters';
import { ExportButton } from 'ui/ExportButton/ExportButton';
import Loader from 'ui/Loader';
import Table from 'ui/Table';
import { dateHelpers } from 'utils/helpers/dateHelpers';
import type { TComponent } from 'utils/types/component';

import { useOnce } from 'components/utils/hooks/useOnce';

import { Filters } from './components/Filters';
import { CustomPagination } from './components/Paginations';
import { COLUMNS } from './columns';
import styles from './HistorySettlements.module.scss';

export const HistorySettlements: TComponent = () => {
    const data = useHistorySettlementsTable(
        historySettlementsTableSelectors.rows,
    );
    const loading = useHistorySettlementsTable(
        historySettlementsTableSelectors.isLoading,
    );
    const fetchHistorySettlements = useHistorySettlementsTable(
        historySettlementsTableSelectors.getTable,
    );
    const exportSettlements = useHistorySettlementsTable(
        historySettlementsTableSelectors.exportSettlements,
    );

    const body = useHistorySettlementsTableFilters(
        historySettlementsTableFiltersSelectors.getBody,
    );
    const params = useHistorySettlementsTableFilters(
        historySettlementsTableFiltersSelectors.getParams,
    );

    const handleExport = () => {
        const startDate = body.startDate;
        const endDate = body.endDate;

        if (startDate && endDate) {
            const isWithin14Days = dateHelpers.isWithin14Days(
                startDate,
                endDate,
            );

            if (!isWithin14Days) {
                return notification.error({
                    message:
                        'Максимально возможный период для выгрузки списка транзакций - 14 дней.',
                });
            }

            exportSettlements({
                startDate,
                endDate,
            });
        } else {
            notification.error({
                message:
                    'Экспорт таблицы возможно только при установленных фильтрах по дате.',
            });
        }
    };

    useOnce(
        () => true,
        () => {
            fetchHistorySettlements(body, params);
        },
        [],
    );

    return (
        <Loader active={loading}>
            <div className={styles.container}>
                <div className={cn(styles.actionBar, 'actionBar')}>
                    <Filters />
                    <div className={styles.rightControls}>
                        <ExportButton onClick={handleExport} />
                    </div>
                </div>
                <Table
                    columns={COLUMNS}
                    tableRows={data}
                    onChange={() => {}}
                    sortDirections={['ascend', 'descend']}
                />
                <div className={styles.tableBottom}>
                    <CustomPagination />
                </div>
            </div>
        </Loader>
    );
};
