import type { THistorySettlement } from 'models/Merchant/HistorySettlement';
import type { TTableColumn } from 'models/Table';

export const COLUMNS: TTableColumn<THistorySettlement>[] = [
    {
        key: 'identity',
        title: 'Identity',
        dataIndex: 'identity',
    },
    {
        key: 'createdBy',
        title: 'Email пользователя',
        dataIndex: 'createdBy',
    },
    {
        key: 'before',
        title: 'До',
        dataIndex: 'before',
        render: (_, record) =>
            `${record.amountBefore} - ${record.amountBeforeUsd}`,
    },
    {
        key: 'after',
        title: 'После',
        dataIndex: 'after',
        render: (_, record) =>
            `${record.amountAfter} - ${record.amountAfterUsd}`,
    },
];
